import "./Press.scss";

import altcion from "../../resourses/Flow_landing_assets/altcoinbuzz_image.png";
import altcionLogo from "../../resourses/Flow_landing_assets/altcoinbuzzLOGO.png";
import crypto from "../../resourses/Flow_landing_assets/cryptogamingpool_image.png";
import cryptoLogo from "../../resourses/Flow_landing_assets/cryptogamingpoolLOGO.png";
import decrypt from "../../resourses/Flow_landing_assets/decrypt_image.png";
import decryptLogo from "../../resourses/Flow_landing_assets/decryptLOGO.png";
import castle from "../../resourses/Flow_landing_assets/castlecrypto_image.png";
import castleLogo from "../../resourses/Flow_landing_assets/castlecryptoLOGO.png";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

function Press() {
  return (
    <div className={"press"}>
      <h2>PRESS ABOUT US</h2>
      <p>Interested in what industry experts think about us? Welcome!</p>
      <LazyLoadComponent threshold={1000}>
        <div className={"press-news"}>
          <div>
            <a
              href="https://decrypt.co/15221/6-of-the-most-anticipated-blockchain-video-games"
              target={"_blank"}
            >
              <img src={decryptLogo} alt="" />
            </a>
            <a
              href="https://decrypt.co/15221/6-of-the-most-anticipated-blockchain-video-games"
              target={"_blank"}
            >
              <img src={decrypt} alt="" />{" "}
            </a>
          </div>
          <div id={"altcoin-wrapper"}>
            <a
              href="https://www.altcoinbuzz.io/blockchain-gaming/gaming-interviews/exclusive-interview-dark-country-is-giving-away-nfts/"
              target={"_blank"}
            >
              <img src={altcionLogo} alt="" />
            </a>{" "}
            <a
              href="https://www.altcoinbuzz.io/blockchain-gaming/gaming-interviews/exclusive-interview-dark-country-is-giving-away-nfts/"
              target={"_blank"}
            >
              <img src={altcion} alt="" />{" "}
            </a>
          </div>
          <div id={"crypto-wrapper"}>
            <a
              href="https://cryptogamingpool.com/en/announcing-dark-country-project/"
              target={"_blank"}
            >
              <img src={cryptoLogo} alt="" />
            </a>
            <a
              href="https://cryptogamingpool.com/en/announcing-dark-country-project/"
              target={"_blank"}
            >
              <img src={crypto} alt="" />
            </a>
          </div>
          <div>
            <a
              href="https://www.castlecrypto.gg/dark-country-cross-blockchain-gothic-horror-ccg/"
              target={"_blank"}>
              <img src={castleLogo} alt="" />
            </a>
            <a
              href="https://www.castlecrypto.gg/dark-country-cross-blockchain-gothic-horror-ccg/"
              target={"_blank"}>
              <img src={castle} alt="" />
            </a>
          </div>
        </div>
      </LazyLoadComponent>
    </div>
  );
}

export default Press;
