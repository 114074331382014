import Loader from "react-loader-spinner";
import React from "react";
import "./SpinnerLoader.scss";
import dc from '../../resourses/Flow_landing_assets/dcLOGO.png'

const SpinnerLoader = () => {
  return (
    <div className={"spinner-loader"}>
        <div><img src={dc} alt=""/></div>
      <Loader type="Oval" color="#00BFFF" height={80} width={80} color={'red'} />
    </div>
  );
};

export default SpinnerLoader;
