import "./Footer.scss";
import dcLogo from "../../resourses/Flow_landing_assets/dcLOGO.png";
import itch from "../../resourses/Flow_landing_assets/inchLOGO.png";
import telegram from "../../resourses/Flow_landing_assets/telegramLOGO.png";
import discord from "../../resourses/Flow_landing_assets/discordLOGO.png";
import medium from "../../resourses/Flow_landing_assets/mediumLOGO.png";
import twitter from "../../resourses/Flow_landing_assets/twitterLOGO.png";
import { Link } from "react-scroll";
import { LazyLoadComponent } from "react-lazy-load-image-component";

function FreeVirgin() {
  return (
    <div className={"footer"}>
      <h2>JOIN THE GAME</h2>
      <p>Love the Trading Card Games like we do? Try the game right away!</p>

      <div className={"logos-wrapper"}>
        <div>
          <a target={"_blank"} href={"https://darkcountry.io/"}>
            <img src={dcLogo} alt="" />
            <p>Visit out website</p>
          </a>
        </div>
        <div>
          <a
            href={"https://darkcountry.itch.io/dark-country"}
            target={"_blank"}
          >
            <img src={itch} alt="" />
            <p>Download and play</p>
          </a>
        </div>
      </div>

      <div className={"contacts"} id={"contact"}>
        <h3>Contact us</h3>
        <p>
          If you have any questions about Land assets, please contact us in
          following ways
        </p>

        <div className={"contact-icons"}>
          <div>
            <a href={"https://t.me/dark_country"} target={"_blank"}>
              <img src={telegram} alt="" />
              <span>@DARK_COUNTRY</span>
            </a>
          </div>
          <div>
            <a href="https://discord.gg/8R4SaZY" target={"_blank"}>
              <img src={discord} alt="" />
              <p>@DARK_COUNTRY</p>
            </a>
          </div>
          <div>
            <a
              href="https://darkcountrygame.medium.com/
"
              target={"_blank"}
            >
              <img src={medium} alt="" />
              <p>Dark Country</p>
            </a>
          </div>
          <div>
            <a href={"https://twitter.com/DarkCountryGame"}>
              <img src={twitter} alt="" />
              <p>@DarkCountryGame</p>
            </a>
          </div>
        </div>
      </div>

      <div className={"footer-nav"}>
        <span>
          <Link to="packs" smooth={true} href={""} duration={5000}>
            Packs
          </Link>
        </span>
        <span>
          <Link to="whitelist" smooth={true} href={""} duration={4000}>
            Whitelist
          </Link>
        </span>
        <span>
          <Link to="about" smooth={true} href={""} duration={3000}>
            About DC
          </Link>
        </span>
        <span>
          <Link to="lands" smooth={true} href={""} duration={3000}>
            Lands
          </Link>
        </span>
        <span>
          <Link to="vampires" smooth={true} href={""} duration={3000}>
            Vampires
          </Link>
        </span>
        <span>
          <Link to="unpacking" smooth={true} href={""} duration={3000}>
            Unpacking
          </Link>
        </span>
        <span>
          <Link to="roadmap" smooth={true} href={""} duration={3000}>
            Roadmap
          </Link>
        </span>
        <span>
          <Link to="promo" smooth={true} href={""} duration={2000}>
            Promo
          </Link>
        </span>
        <span>
          <Link to="contact" smooth={true} href={""} duration={2000}>
            Contact
          </Link>
        </span>
      </div>

      <div className={"authors"}>
        <p className={"dark-grey"}>℗ Immortal Games 2019-2021</p>
        <p className={"dark-grey"}>© Dark Country.io</p>
      </div>
    </div>
  );
}

export default FreeVirgin;
