import bronzeVideo from '../../resourses/3d/bronze_60F_x264.mp4'
import silverVideo from '../../resourses/3d/Silver_60F_x264.mp4'
import goldVideo from '../../resourses/3d/Gold_60F_x264.mp4'

import {useEffect, useState} from 'react'

import "./Packs.scss";


function Packs() {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    let isMobile = width <= 1024

    const mouseOver = (e) => {
        e.target.play()
    }
    const mouseOut = (e) => {
        e.target.pause()
    }

    return (
        <div className={"packs"} id={"packs"}>
            <p className={"title"}>
                <p>Choose your</p>
                <p>
                    <span className={"red"}>Land Pack</span> and get Unique Vampire Cards!
                </p>
            </p>
            <p className={"details"}>
                Each pack contains land plot(s), so once unpacked you will be able to
                claim a land.
            </p>

            <div className={"packs-wrapper"}>
                <div className={"single-pack shake"}>
                    <div>
                        <div className={"print"}>
                            <p>X2100</p>
                        </div>
                        {/*<RandomSlide withRotation={true}>*/}
                        {/*  <img src={bronze} alt="" />*/}
                        <video loop={true} muted={true} src={bronzeVideo}
                               autoPlay={isMobile}
                               onMouseOver={isMobile ? '' : mouseOver}
                               onMouseOut={isMobile ? '' : mouseOut}
                        >
                        </video>
                        {/*</RandomSlide>*/}
                    </div>
                    <div className={"pack-info"}>
                        <p>Rancho pack</p>
                        <p>CONTAINS:</p>
                        <p>1 land plot</p>
                        <p>+ 5 cards</p>
                        <p>Price : 7 FLOW</p>
                    </div>
                </div>
                <div className={"single-pack"}>
                    <div>
                        <div className={"print"}>
                            <p>X300</p>
                        </div>
                        {/*<RandomSlide withRotation={true}>*/}
                        {/*  <img src={silver} alt="" />*/}
                        <video loop={true} muted={true} src={silverVideo}
                               autoPlay={isMobile}
                               onMouseOver={isMobile ? '' : mouseOver}
                               onMouseOut={isMobile ? '' : mouseOut}
                        >
                        </video>
                        {/*</RandomSlide>*/}
                    </div>
                    <div className={"pack-info"}>
                        <p>Mayor's pack</p>
                        <p>CONTAINS:</p>
                        <p>3 land plots</p>
                        <p>+ 15 cards</p>
                        <p>Price : 20 FLOW</p>
                    </div>
                </div>
                <div className={"single-pack"}>
                    <div>
                        <div className={"print"}>
                            <p>X100</p>
                        </div>
                        {/*<RandomSlide withRotation={true}>*/}
                        <video loop={true} muted={true} src={goldVideo}
                               autoPlay={isMobile}
                               onMouseOver={isMobile ? '' : mouseOver}
                               onMouseOut={isMobile ? '' : mouseOut}
                        >
                        </video>
                        {/*</RandomSlide>*/}
                    </div>
                    <div className={"pack-info"}>
                        <p>Governor's Pack</p>
                        <p>CONTAINS:</p>
                        <p>10 land plots</p>
                        <p>+ 50 cards</p>
                        <p>Price : 60 FLOW</p>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Packs;
