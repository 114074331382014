import React, { useState, useEffect } from "react";

import "./App.css";

import bgimage from "./resourses/Flow_landing_assets/bgFLOW3.jpg";
import startImage from "./resourses/Flow_landing_assets/world_map_image_main.png";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import SpinnerLoader from "./components/SpinnerLoader/SpinnerLoader";
import Landing from "./components/Landing/Landing";

function App() {
  const [bgLoaded, setLoad] = useState(false);
  const [src, setSrc] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const bg = new Image();
    bg.src = bgimage;

    const img = new Image();
    img.src = startImage;

    img.onload = function () {
      setImageLoaded(true)
    }

    bg.onload = function () {
      setTimeout(() => {
        setSrc(bgimage);
      }, 2000)

      setTimeout(() => {
        setLoad(true);
      }, 4000);
    };
  }, []);

  return (
    <div className="App">
      {!bgLoaded ? <SpinnerLoader /> : ""}

      {src && imageLoaded ? <Landing bg={src} visibility={bgLoaded} /> : ""}
      {/*<Landing bg={src} visibility={true} />*/}
      <ToastContainer
          position="bottom-left"
          autoClose={4000}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
      />
    </div>
  );
}

export default App;
