import "./FreeVirgin.scss";

import virgin from "../../resourses/Flow_landing_assets/free_virgin_image.png";
import Gesture from "../AnimationComponents/Gesture/Gesture";
import { LazyLoadComponent } from "react-lazy-load-image-component";

function FreeVirgin() {
  return (
    <div className={"free-virgin"}>
      <h2>STAY IN TOUCH AND GET A FREE VIRGIN NFT-CARD!</h2>
      <div>
        <div className={"text-wrapper"}>
          <p>Still thinking but don't want to miss your chance? GOOD!</p>
          <p>Leave your e-mail and get a special Virgin NFT-card for free!</p>
          <p>
            The Virgin cards will be distributed among all the e-mails 2 days
            after our Land Sale ends.
          </p>
          <p>
            We'll keep you updated via e-mails and in our social media(Facebook,
            Telegram and Discord) channels
          </p>
          <p className={"text-end"}>GOOD LUCK AND HAVE FUN!</p>

          <div className={"virgin-input"}>
            <input type="text" placeholder={"Leave your email"} />

            <Gesture>
              <span>
                <a href={""}>SUBMIT</a>
              </span>
            </Gesture>
          </div>
        </div>
        <LazyLoadComponent>
          <div className={"image-wrapper"}>
            <img src={virgin} alt="" />
          </div>
        </LazyLoadComponent>
      </div>
    </div>
  );
}

export default FreeVirgin;
