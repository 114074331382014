import worldMap from "../../resourses/Flow_landing_assets/world_map_image.png";
import ownRentEarn from "../../resourses/Flow_landing_assets/own_rent_map_image.png";
import build from "../../resourses/Flow_landing_assets/build_image.png";
import explore from "../../resourses/Flow_landing_assets/explore_image.png";
import create from "../../resourses/Flow_landing_assets/create_image.png";
import { LazyLoadComponent } from "react-lazy-load-image-component";

import "./Lands.scss";
import {
  SlideInLeft,
  SlideInRight,
} from "../AnimationComponents/SlideIn/SlideIn";
import FadeInWhenVisible from "../AnimationComponents/FadeInWhenVisible/FadeInWhenVisible";

function Lands() {
  return (
    <div className={"lands"} id={"lands"}>
      <h2>What are the Dark Country Lands(NFTs)?</h2>
      <div className={"info-wrapper"}>
        <p>
          We offer you the multichain lands NFTs, designed to play,
          collaborate, fight across 4 worlds.
        </p>
        <p>Only 4000 land pieces will be available to FLOW users.</p>
        <p className={"bold"}>
          Dark country lands add a new layer to the player's game experience and
          are the ultimate game assets that players can own in the Dark Country
          universe. Lands provide access to Adventure zones and generate Shadow
          Dimes currency for both landowners and players.
        </p>
        <p>
          Read more about lands here...{" "}
          <a href={"https://darkcountrygame.medium.com/the-dark-country-lands-universe-introduction-ca551ba2b5c6"} target={'_blank'}>
            <span className={"red"}>(link to Medium)</span>
          </a>
        </p>
      </div>

      <LazyLoadComponent threshold={1000}>
        <div className={"world-map"}>
          <FadeInWhenVisible>
            <img src={worldMap} alt="" />
          </FadeInWhenVisible>
        </div>

        <SlideInLeft>
          <div className={"land-block own-earn"}>
            <img src={ownRentEarn} alt="" />
            <ul>
              <p>Own, Rent, Earn, Farm</p>
              <li>
                Grab a share of in-game currencies from Dark Portal and
                adventures
              </li>
              <li>Receive fees various in-game activities</li>
              <li>Rent land to someone and get income</li>
              <li>Enable card borrowing</li>
              <li>Farm NFTs and more!</li>
            </ul>
          </div>
        </SlideInLeft>

        <SlideInRight>
          <div className={"land-block"}>
            <ul>
              <p>Build</p>
              <li>Black Market - Place to buy and sell items</li>
              <li>Blacksmith - Place to craft weapons</li>
              <li>Tailor - Place to craft clothes</li>
              <li>Tavern - quest building</li>
              <li>Alchemy lab - potion crafting</li>
              <li>Library - magic scroll crafting</li>
            </ul>
            <img src={build} alt="" />
          </div>
        </SlideInRight>
        <SlideInLeft>
          <div className={"land-block explore"}>
            <img src={explore} alt="" />
            <ul>
              <p>Explore</p>
              <li>Complete quests</li>
              <li>Run into adventures</li>
              <li>Beat portal beasts</li>
            </ul>
          </div>
        </SlideInLeft>
        <SlideInRight>
          <div className={"land-block"}>
            <ul>
              <p>Create</p>
              <li>Upgrade cards</li>
              <li>Craft clothes, weapons and scrolls</li>
              <li>Summon minions</li>
            </ul>
            <img src={create} alt="" />
          </div>
        </SlideInRight>
      </LazyLoadComponent>
    </div>
  );
}

export default Lands;
