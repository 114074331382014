import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import React, { useEffect } from "react";

function getRandomSing() {
  const min = Math.ceil(0);
  const max = Math.floor(10);
  const sign = Math.floor(Math.random() * (max - min)) + min >= 5 ? "+" : "-";
  return sign; //Максимум не включается, минимум включается
}

export const RandomSlide = ({ children, withRotation }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  let rotate = withRotation ? `${Math.random() * 360}deg`: 0

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    return () => controls.start("hidden");
  }, [inView]);

  const variants = {
    hidden: {
      x: `${getRandomSing()}${Math.floor(Math.random() * 200)}px`,
      y: `${getRandomSing()}${Math.floor(Math.random() * 200)}px`,
      rotate
    },
    visible: {
      x: "0px",
      y: "0px",
      rotate: 0,
    },
  };
  return (
    <motion.div
      ref={ref}
      animate={controls}
      variants={variants}
      transition={{ duration: 1.2, type: "easeOut" }}
      initial={"hidden"}
    >
      {children}
    </motion.div>
  );
};

export default RandomSlide;
