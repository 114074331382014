import "./Unpacking.scss";

import key from "../../resourses/Flow_landing_assets/keyicon.png";
import box from "../../resourses/Flow_landing_assets/unpackicon.png";
import { LazyLoadComponent } from "react-lazy-load-image-component";

function Unpacking() {
  return (
    <div className={"unpacking"} id={"unpacking"}>
      <div className={"unpacking-wrapper"}>
        <div>
          <div className={"image-wrapper"}>
            <img src={key} alt="" />
          </div>
          <div className={"text-wrapper"}>
            <h3>You've got a land plot, what's next</h3>
            <p>
              Land plot provides you with guaranteed access to the land.
              However, lands can be different in terms of their rarity and how
              close they are to a center of the map.
            </p>
            <p>
              The exact land plots positions will be determined randomly within
              the land rarities zones during Land packs unpacking.
            </p>
          </div>
        </div>
        <div>
          <div className={"image-wrapper"}>
            <img src={box} alt="" />
          </div>
          <div className={"text-wrapper"}>
            <h3>The unpacking process</h3>
            <p>
              Your packs will be available immediately when a purchase happens.
            </p>
            <p>
              You can keep or trade them. Unpacking will be available within 4 weeks
              after sale is done. Feel free to leave your email, so we will
              specifically let you know when unpacker gonna be live.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unpacking;
